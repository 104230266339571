import './index.css';
import {Suspense, useRef,useState} from 'react'
import { Canvas} from '@react-three/fiber'
import {OrbitControls, useGLTF} from '@react-three/drei'
import {useLoader, useFrame} from '@react-three/fiber'
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import UploadFileIcon from "@mui/icons-material/UploadFile";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  backgroundColor: '#d9e4ea',
  
  color: theme.palette.text.secondary,
}));




export function Model(props) {
  const { nodes, materials } = useGLTF('/cat/1234567810.gltf')
    const textureMap= useLoader(TextureLoader, props.customColors.file == undefined?'zayac.jpg':props.customColors.file);
    const textureMap1= useLoader(TextureLoader, 'bumaga.jpeg');
    
  textureMap.flipY=false
  
  // textureMap.repeat.set = [3, 3]
  // textureMap.rotation = [1.55]
  
  return (
    <group {...props} dispose={null} scale={40} position={[0, 2, 0]} >


      <mesh geometry={nodes['96925C0FB1C80FDF9F304888D9586C530BAA3922||B8A4396250C5687B1D31E70758B30729E1AC4426'].geometry} material={materials['Material #25']} rotation={[0, 0, Math.PI / 2]}><meshStandardMaterial map={textureMap1} attach='material'/></mesh>
      <mesh geometry={nodes['96925C0FB1C80FDF9F304888D9586C530BAA3922||B8A4396250C5687B1D31E70758B30729E1AC4427'].geometry} material={materials['02 - Default']} rotation={[0, 0, Math.PI / 2]} scale={[0.99, 1.01, 1.01]} ><meshStandardMaterial map={textureMap} attach='material'/></mesh>

    </group>
  )
}









function App() {
  
  const [crop, setCrop] = useState({
    unit: "%",
    width: 60,
    height: 50,
    
   
    
    
  })
  const [mesh,setMesh] = useState("#ffffff")
  const [stripes,setStripes] = useState("#ffffff")
  const [soul,setSoul] = useState("#ffffff")
  const [imageToCrop, setImageToCrop] = useState(undefined);
  const [croppedImage, setCroppedImage] = useState(undefined);
  const [imageRef, setImageRef] = useState();
  const [im, setIm] = useState();
  const [urlim, setUrlim] = useState()
  
  const [file, setFile] = useState('zayac.jpg');
  const hiddenFileInput = useRef(null);
  const handleClickButton = event => {
    hiddenFileInput.current.click();
  };
    function handleChange(e) {
      
        setFile(URL.createObjectURL(e.target.files[0]));
        
        
    }

   async  function cropImage() {
      
      if (imageRef && crop.width && crop.height) {
        const croppedImage = await getCroppedImage(
          imageRef,
          crop,
          "croppedImage.jpeg" // destination filename
        );
        setUrlim(croppedImage)
        // calling the props function to expose
        // croppedImage to the parent component
        
      }
    }


    function onImageLoad(e) {
      const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
      if (e){
      const widthImage = e.target.width
      const heightImage = e.target.height
      const naturalWidth = e.target.naturalWidth
      const naturalHeight = e.target.naturalHeight
      setImageRef({widthImage: widthImage,
                  heightImage:heightImage,
                  naturalWidth: naturalWidth,
                  naturalHeight: naturalHeight
                })
                setIm(e)
              }
      
    }


    function getCroppedImage(imageRef, crop, fileName) {
      // creating the cropped image from the source image
      
      const canvas = document.createElement("canvas");
      const scaleX = imageRef.naturalWidth / imageRef.widthImage;
      const scaleY = imageRef.naturalHeight / imageRef.heightImage;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext("2d");
  
      ctx.drawImage(
        im.target,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
      
      return new Promise((resolve, reject) => {
        canvas.toBlob((blob) => {
          // returning an error
          if (!blob) {
            reject(new Error("Canvas is empty"));
            return;
          }
  
          blob.name = fileName;
          // creating a Object URL representing the Blob object given
          const croppedImageUrl = window.URL.createObjectURL(blob);
          
          resolve(croppedImageUrl);
        }, "image/jpeg");
      });
    }



// console.log(crop)
  return (
   
     <Box style={{textAlign: 'center',  marginTop:"3rem", }}>
      <h1>Конструктор стаканов</h1>
     <h3 style={{marginBottom:"2rem"}}>Посмотрите, как будет выглядеть Ваш новый стаканчик!</h3>
      <Grid container justifyContent="center" spacing={2} style={{padding: "30px"}}>
        
        <Grid item style={{height: "500px", width:"500px", padding:'20px', marginRight: "3rem", marginLeft:"3rem"}}>
        
        <Canvas linear flat >
                      <Suspense fallback={null} >
                      
                          <ambientLight intensity={0.7}/>
                          {/* <directionalLight position={[1, 1, 1]} intensity={0.7} />
                          <directionalLight position={[-1, 1, 1]} intensity={0.4} /> */}
                          <spotLight intensity={0.8} 
                                     angle={0.1} 
                                     penumbra={1} 
                                     position={[25,50,25]}
                                     castShadow />
                          <spotLight intensity={0.8} 
                                     angle={0.1} 
                                     penumbra={1} 
                                     position={[-25,50,-25]}
                                     castShadow />
                          
                          <Model customColors={{file:urlim }} />
                          <OrbitControls enablePan={false}
                                         enableZoom={false}
                                         enableRotate={true}
                                         autoRotate={true}/>
                          
                      </Suspense>
                   </Canvas>
          
        </Grid>
        <Grid item  >
         <Item style={{}}>
         <Stack
            direction="column"
            spacing={2}
            style={{textAlign: 'left', padding: '20px'}}
          >
            <h3>1. Добавьте фото</h3>
            <h3>2. Выделите область на фото</h3>
          </Stack>
             <Stack spacing={2} style={{paddingBottom:"20px"}}>
              <input type="file" hidden ref={hiddenFileInput} onChange={handleChange}/> 
                <Button variant="contained"  startIcon={<UploadFileIcon />} sx={{ marginRight: "1rem" }}  onClick={handleClickButton} >Добавить картинку</Button>
                
              </Stack>
          
              <ReactCrop ruleOfThirds crop={crop} style={{objectFit: "contain"}} onChange={(crop, percentCrop) => setCrop(crop)} aspect={18.5 / 9} onComplete={() => cropImage()}>
                <div >
                   <img src={file} onLoad={onImageLoad} style={{maxHeight: '400px', maxWidth: '100%'}}/>
                </div>
                
              </ReactCrop>
          

              

                  
                  
                  
                    
                      {/* <img src={file} /> */}
            
          </Item>
        </Grid>
         {/* <img src={urlim} /> */}
      </Grid>
      </Box>
 



        
   
  );
}

export default App;
